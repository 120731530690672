import { graphql, useStaticQuery } from "gatsby";

export const useLegacyImage = (image_names) => {
  if(typeof image_names !== 'object') {
    image_names = [image_names];
  }
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "legacy"}, ext: {ne: ".svg"}}) {
        edges {
          node {
            name
            ext
            image: childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            publicURL
          }
        }
      }
    }
  `)
  return data.allFile.edges.filter(e => image_names.includes(`${e.node.name}${e.node.ext}`));
}