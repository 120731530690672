import React, { useRef } from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import IntroSlides from "../legacyHomepage/IntroSlides";
import { InView } from "react-intersection-observer";
import useHomeBrandTabs from "../legacyHomepage/useHomeTabBrands";
import Slides from "../legacyHomepage/Slides";
import Page from "../legacyHomepage/Page";
import * as landingStyles from "../legacyHomepage/Landing.module.scss";
import * as videoStyles from "../legacyHomepage/Video.module.scss";
import * as bodyStyles from "../legacyHomepage/homepageBody.module.scss";
import { useLegacyImage } from "../hooks/use-legacy-image";
import ScrollingButton from "../legacyHomepage/ScrollingButton";
import ServicesGrid from "../legacyHomepage/ServicesGrid";
import HomepageServices from "../legacyHomepage/HomepageServices";
import SelectableImage from "../legacyHomepage/SelectableImage";
import ReactPlayer from "react-player";
import toOptimisedImg from "../legacyHomepage/OptimisedImgUtil";
import HomepageStat from "../legacyHomepage/HomepageStat";
import CircularImage from "../legacyHomepage/CircularImage";
import ScrollableQuote from "../legacyHomepage/ScrollableQuote";
import BrandTabReverse from "../legacyHomepage/BrandTabReverse";
import Carousel from "../legacyHomepage/Carousel";
import ProjectCarouselItem from "../legacyHomepage/ProjectCarouselItem";
import BrandTab from "../legacyHomepage/BrandTab";
import ContactForm from "../components/ContactForm";

import useGSScrollFromTo from "../legacyHomepage/useGSScrollFromTo";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const GSScrollFromTo = ({
  as = "div",
  className,
  children,
  start,
  end,
  from,
  to,
  style = {},
  ...scrollTriggerProps
}) => {
  const { ref } = useGSScrollFromTo({
    start,
    end,
    from,
    to,
    scrollTriggerProps,
  });

  return React.createElement(as, { ref, className, style }, children);
};

export const IconPlayCircle = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      fill="white"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none"></rect>
      <circle
        cx="128"
        cy="128"
        r="96"
        fill="none"
        stroke="white"
        strokeMiterlimit="10"
        strokeWidth="8"
      ></circle>
      <polygon
        points="160 128 112 96 112 160 160 128"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
      ></polygon>
    </svg>
  );
};

const IndexPage = () => {
  const getLegacyImage = useLegacyImage;
  const { onInViewChange } = useHomeBrandTabs();
  const ref = useRef();
  const handleScroll = () => {
    //.current is verification that element has rendered
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const PartnerLogos = [
    "tiffany-co-logo.png",
    "blaq-logo.png",
    "vush-logo.png",
    "anytime-fitness-logo.svg",
    "frank-body-logo.png",
    "cee-clear-logo.png",
  ];

  const BrandLogos = [
    "forbes-logo.svg",
    "financial-review-logo.svg",
    "entreprenur-logo.svg",
    "au-manufacturing.svg",
    "yahoo-logo.png",
    "inside-small-business-logo.png",
  ];

  const stats = [
    {
      name: "AUSTRALIAN OWNED",
      value: 100,
      before: null,
      after: "%",
      textonly: false,
      text: 100,
      homepage: false,
    },
    {
      name: "OF GOODS MANUFACTURED & IMPORTED",
      value: 110,
      before: "$",
      after: "+",
      textonly: false,
      text: "110",
      homepage: false,
    },
    {
      name: "TEAM MEMBERS WORLDWIDE",
      value: 60,
      before: null,
      after: "+",
      textonly: false,
      text: "60",
      homepage: false,
    },
    {
      name: "TYPES OF PRODUCTS MANUFACTURED",
      value: 500,
      before: null,
      after: "+",
      textonly: false,
      text: "500",
      homepage: false,
    },
  ];

  return (
    <main>
      <InView onChange={onInViewChange} threshold={0.5}>
        {({ ref }) => (
          <div ref={ref}>
            <Slides slides={IntroSlides} handleScroll={handleScroll} />
          </div>
        )}
      </InView>

      <Page noPad>
        <div className={landingStyles.layout} ref={ref}>
          <div className={landingStyles.toothbrushDiv}>
            <GSScrollFromTo
              className={landingStyles.image}
              start="top bottom"
              end="bottom top"
              from={{
                yPercent: 10,
              }}
              to={{
                yPercent: -10,
              }}
            >
              <SelectableImage
                src={getLegacyImage("bach-eyewear-1.webp")[0].node.publicURL}
                label="Bach Eyewear"
                to="/projects/bach-eyewear"
                bg="#9297a0"
                color="white"
                mobile
                mobileposition={{
                  bottom: "20px",
                  left: "15px",
                }}
                parallax
              />
            </GSScrollFromTo>

            <h1 className={landingStyles.tagline}>
              Right now.
              <br />
              Make your business,
              <br />
              product or invention
              <br />a reality.
            </h1>
            <h1 className={landingStyles.tagline} id={landingStyles.last_h1}>
              With Sourci&nbsp;
              <br className={landingStyles.mobileBr} />
              as your <br className={landingStyles.br} />
              sourcing&nbsp;
              <br className={landingStyles.mobileBr} />
              partner, <br className={landingStyles.br} />
              you've&nbsp;
              <br className={landingStyles.mobileBr} />
              got this.
            </h1>
          </div>
          <div className={landingStyles.processText}>
            <p className="mb-4">
              Navigating the complex world of sourcing, we partner with
              fast-growing & established E-Commerce brands to help us procure,
              secure, produce, launch or deliver products from all over the
              global. Leading the process, we connect you with the right
              suppliers, designers and makers - so together, we make amazing
              happen.
            </p>
            <ScrollingButton
              text="Learn more about our process"
              bg="#ff692c"
              color="white"
              to="/process"
              big
            />
          </div>
          <Page>
            <ServicesGrid services={HomepageServices} homepage />
          </Page>

          <div className={landingStyles.div2}>
            <h6 className={landingStyles.tagline}>
              Together, let's pull <br className={landingStyles.mobileBr} />
              the strings.&nbsp;
              <br />
              Find the suppliers, <br className={landingStyles.mobileBr} />
              creators,&nbsp;
              <br className={landingStyles.br} />
              importers
              <br className={landingStyles.mobileBr} /> &amp; producers.
            </h6>
            <h6 className={landingStyles.tagline}>
              Forget about the&nbsp;
              <br className={landingStyles.mobileBr} />
              person who knows
              <br />
              a person. Talk to <br className={landingStyles.mobileBr} />
              the fire-starter,
              <br />
              rainmaker, the <br className={landingStyles.mobileBr} />
              horse's mouth.
            </h6>
          </div>
        </div>

        <div className={videoStyles.layoutGrid}>
          <div className={videoStyles.video_inner_ctn}>
            <ReactPlayer
              className={videoStyles.react_player}
              playIcon={<IconPlayCircle />}
              controls
              light={toOptimisedImg(
                getLegacyImage("team-cover.webp")[0].node.publicURL,
                2175
              )}
              width="100%"
              height="100%"
              url="https://www.youtube.com/watch?v=5SWyLMQUMu4&t=1s"
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 1,
                    modestbranding: 1,
                  },
                },
              }}
            />
          </div>
          <div className={videoStyles.big_text}>
            We are Giovanni and Ellie - the founders of Sourci, and together
            with our global team, we're going to make amazing happen for you!
          </div>
        </div>

        <HomepageStat stats={stats} />

        <CircularImage
          url={getLegacyImage("vush-home-image.webp")[0].node.publicURL}
          label="Vush Stimulation"
          to="/projects/vush"
          color="white"
          bg="#332124"
        />

        <ScrollableQuote quote="Order 10, 100, 5000... a gazillion" />

        <div className={bodyStyles.layout}>
          <div className={bodyStyles.bowlDiv}>
            <GSScrollFromTo
              start="top bottom"
              end="bottom top"
              from={{
                xPercent: -15,
              }}
              to={{ xPercent: -5 }}
              className={bodyStyles.bowlImgPlx}
              style={{ width: "fit-content" }}
            >
              <SelectableImage
                alt="35mm Co."
                className={bodyStyles.bowlImg}
                parallax
                src={getLegacyImage("35mm-co-homepage.jpg")[0].node.publicURL}
                label="35mm Co."
                to="/projects/35mm-co"
                color="white"
                bg="#348cbd"
                mobile
                mobileposition={{
                  bottom: "26px",
                  right: "26px",
                }}
              />
            </GSScrollFromTo>
            <GSScrollFromTo
              className={bodyStyles.bowlText}
              start="top bottom"
              end="bottom top"
              from={{
                yPercent: 10,
              }}
              to={{
                yPercent: -10,
              }}
            >
              <h1 className={bodyStyles.tagline}>
                Go ahead,
                <br />
                hit the&nbsp;
                <GSScrollFromTo
                  as="span"
                  start="top 80%"
                  end="bottom 50%"
                  from={{
                    color: "#000000",
                  }}
                  to={{
                    color: "#ed76bc",
                  }}
                >
                  get
                  <br />
                  started
                </GSScrollFromTo>
                &nbsp;
                <br className={bodyStyles.mobileBr} />
                button.
                <br />
              </h1>
            </GSScrollFromTo>
          </div>
          <div
            className={bodyStyles.cameraDiv}
            style={{ marginTop: "3rem", marginBottom: "3rem" }}
          >
            <GSScrollFromTo
              start="top bottom"
              end="bottom top"
              from={{
                xPercent: 10,
              }}
              to={{
                xPercent: -10,
              }}
              className={bodyStyles.cameraImgPlx}
            >
              <SelectableImage
                src={
                  getLegacyImage("safely-secured-homepage.jpg")[0].node
                    .publicURL
                }
                alt="Sourci product image"
                className={bodyStyles.cameraImg}
                label="Safely Secured"
                bg="#944302"
                to="/projects/safely-secured"
                parallax
                mobile
                mobileposition={{ bottom: "55%", right: "38%" }}
              />
            </GSScrollFromTo>

            <h6
              className={`${bodyStyles.tagline} ${bodyStyles.scrollWidthText}`}
            >
              <GSScrollFromTo
                as="span"
                start="top 90%"
                end="bottom 50%"
                from={{ width: "0%" }}
                to={{ width: "100%" }}
                style={{ display: "inline-block", overflow: "hidden" }}
              >
                <span>Bang the gong.</span>
              </GSScrollFromTo>
              <br />
              <GSScrollFromTo
                as="span"
                start="top 90%"
                end="bottom 50%"
                from={{ width: "0%" }}
                to={{ width: "100%" }}
                style={{ display: "inline-block", overflow: "hidden" }}
              >
                <span>Hit a drum.</span>
              </GSScrollFromTo>
              <br />
              <GSScrollFromTo
                as="span"
                start="top 90%"
                end="bottom 50%"
                from={{ width: "0%" }}
                to={{ width: "100%" }}
                style={{ display: "inline-block", overflow: "hidden" }}
              >
                <span>Turn things</span>
              </GSScrollFromTo>
              <br />
              <GSScrollFromTo
                as="span"
                start="top 90%"
                end="bottom 50%"
                from={{ width: "0%" }}
                to={{ width: "100%" }}
                style={{ display: "inline-block", overflow: "hidden" }}
              >
                up to 11!
              </GSScrollFromTo>
            </h6>
          </div>
          <div className={bodyStyles.platformDiv}>
            <h6 className={bodyStyles.tagline}>
              This is your&nbsp;
              <GSScrollFromTo
                as="span"
                start="top 90%"
                end="bottom 50%"
                from={{ color: "#000000" }}
                to={{ color: "#FF6CBF" }}
              >
                <h1 className={bodyStyles.tagline}>product sourcing</h1>
              </GSScrollFromTo>
              mission control.
            </h6>
            <h2 className={bodyStyles.h2}>
              We work with brands to assist with:
            </h2>
            <div className={bodyStyles.column_layout}>
              <ul className={bodyStyles.list}>
                <li>Product sourcing</li>
                <li>Product development</li>
                <li>Quality assurance</li>
              </ul>

              <ul className={bodyStyles.list}>
                <li>Manufacturing</li>
                <li>IP protection</li>
                <li>Logistics &amp; distribution</li>
              </ul>

              <ul className={bodyStyles.list}>
                <li>Tactile &amp; Packaging</li>
                <li>Inventory Finance</li>
                <li>Equity &amp; Cashflow Strategies</li>
              </ul>
            </div>
          </div>

          <BrandTabReverse logos={PartnerLogos} />

          <GSScrollFromTo
            as="h1"
            start="top bottom"
            end="bottom 65%"
            from={{
              xPercent: 100,
            }}
            to={{
              xPercent: 0,
            }}
            className={`${bodyStyles.tagline} ${bodyStyles.bigText}`}
          >
            Let's make
            <br />
            amazing happen!
          </GSScrollFromTo>

          <SelectableImage
            outerClassName={bodyStyles.outerDivNike}
            innerClassName={bodyStyles.innerDivNike}
            src={getLegacyImage("au1-office.webp")[0].node.publicURL}
            alt="Sourci Office"
            to="/about-us"
            bg="#ed76bc"
            color="white"
            label="Learn about us"
            mobilebutton
          />

          <Carousel>
            <ProjectCarouselItem
              blurb={`With Sourci's international expertise a manufacturer was identified that enabled the development of our initial highly successful range of Vush's bespoke designs.`}
              attribute="Vush"
              slug="/projects/vush"
              image={getLegacyImage("vush-thumbnail.jpg")[0].node.publicURL}
            />

            <ProjectCarouselItem
              blurb={`It’s also worth mentioning that Sourci and 35mm Co only started working together in February, and in just 4 months a mass production order was being placed.`}
              attribute="35mm Co."
              slug="/projects/35mm-co"
              image={getLegacyImage("35mm-co-2.jpg")[0].node.publicURL}
            />

            <ProjectCarouselItem
              blurb={`CMC Gold have maintained their low defect rate and quality standards. With Sourci's help, Chloe and Maurice were able to scale up in 2020 and achieved a new revenue stream into wholesale.`}
              attribute="CMC Gold"
              slug="/projects/cmc-gold"
              image={getLegacyImage("cmc-gold-1.webp")[0].node.publicURL}
            />

            <ProjectCarouselItem
              blurb={`CEE CLEAR Co-Founders Kat Moses and Morgan Tait struggled to find a bag that could solve these issues they were experiencing. The two go-getters set out to create their own range of PVC transparent accessories ranging from tote bags to cosmetic cases.`}
              attribute="CEE CLEAR"
              slug="/projects/ceeclear"
              image={getLegacyImage("cee-clear-homepage.png")[0].node.publicURL}
            />

            <ProjectCarouselItem
              blurb={`Pink Cosmetics was founded by best friends Aleyna and Anisa. With an expert understanding of all things beauty, they set out to redefine a classic beauty tool – the eyelash curler.`}
              attribute="Pink Cosmetics Co."
              slug="/projects/pinkcosmeticsco"
              image={getLegacyImage("pink-cosmetic-home.png")[0].node.publicURL}
            />

            <ProjectCarouselItem
              blurb={`Several months later we had a finished product that Simon was beyond happy with, Sourci then organised independent testing of the glasses at a third party testing facility to ensure it complied with the required AS/NZ Safety Standards.`}
              attribute="Bach Eyewear"
              slug="/projects/bach-eyewear"
              image={getLegacyImage("bach-eyewear-2.png")[0].node.publicURL}
            />
          </Carousel>

          <BrandTab logos={BrandLogos} />
        </div>
      </Page>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - 35mm Co." />
          </div>
        </div>
      </section>

    </main>
  );
};

export const query = graphql`
  query {
    prismicHomepage {
      data {
        meta_title
        meta_description
      }
    }
  }
`;

export default IndexPage;

export function Head({ data }) {
  const prismicData = data.prismicHomepage.data;
  return (
    <Seo
      title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/"
    />
  );
}
