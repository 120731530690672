// extracted by mini-css-extract-plugin
export var arrow = "Slides-module--arrow---Dp5I";
export var backgroundDiv = "Slides-module--backgroundDiv--vJoqV";
export var bounce = "Slides-module--bounce--c3PJr";
export var br = "Slides-module--br--OMdO8";
export var code = "Slides-module--code--i0B-C";
export var dot = "Slides-module--dot--c4cXQ";
export var dotContainer = "Slides-module--dotContainer--8TybY";
export var headerContainer = "Slides-module--headerContainer--WNRUG";
export var keyword = "Slides-module--keyword---aHLL";
export var link = "Slides-module--link--wdlRl";
export var mobileBr = "Slides-module--mobileBr--jkLIy";
export var orange = "Slides-module--orange--mb6Hg";
export var pink = "Slides-module--pink--A9PqW";
export var slide = "Slides-module--slide--cqb3H";
export var teal = "Slides-module--teal--aILG7";
export var text = "Slides-module--text--aWQ91";
export var white_tagline = "Slides-module--white_tagline--BKWro";