import React from "react";
import * as styles from "./ProjectCarouselItem.module.scss";
import { Link } from "gatsby";
import { animated } from "react-spring";
import ScrollingButton from "./ScrollingButton";
import OptzImage from "./OptzImage";

export default function ProjectCarouselItem(props) {
  const { blurb, attribute, slug, image, style = {} } = props;

  return (
    <animated.div className={styles.layout} style={style}>
      <div className={styles.imageContainer}>
        <OptzImage className={styles.image} src={image} />
      </div>

      <div className={styles.content}>
        <h4 className={styles.header}>Projects</h4>
        <p className={styles.blurb}>{blurb}</p>
        <small className={styles.attribute}>{attribute}</small>

        <Link to={`/${slug}`}>
          <ScrollingButton
            text="Read more"
            bg="#ff692c"
            color="white"
            to={`/${slug}`}
          />
        </Link>
      </div>
    </animated.div>
  );
}
