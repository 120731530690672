// extracted by mini-css-extract-plugin
export var br = "BrandTabReverse-module--br--xSxXW";
export var code = "BrandTabReverse-module--code--YxNNW";
export var grid = "BrandTabReverse-module--grid--mlqZP";
export var lastLogoRow = "BrandTabReverse-module--lastLogoRow--Z12lz";
export var layout = "BrandTabReverse-module--layout--EAZ1e";
export var link = "BrandTabReverse-module--link--IEi88";
export var mobileBr = "BrandTabReverse-module--mobileBr--QMXjy";
export var orange = "BrandTabReverse-module--orange--F4WoL";
export var pink = "BrandTabReverse-module--pink--hmIdk";
export var tab = "BrandTabReverse-module--tab--EmmIY";
export var teal = "BrandTabReverse-module--teal--uy-Vu";