// extracted by mini-css-extract-plugin
export var br = "Carousel-module--br--nsss0";
export var button = "Carousel-module--button--37Nwr";
export var buttonContainer = "Carousel-module--buttonContainer--jvMiw";
export var code = "Carousel-module--code--USjV3";
export var header = "Carousel-module--header--utB6k";
export var layout = "Carousel-module--layout--04Tim";
export var link = "Carousel-module--link--3dpp-";
export var mobileBr = "Carousel-module--mobileBr--SgE9h";
export var orange = "Carousel-module--orange--z9-xq";
export var pink = "Carousel-module--pink--QyGrx";
export var teal = "Carousel-module--teal--6jDlu";