import ScrollingButton from "./ScrollingButton";
import React from "react";
import * as styles from "./ServicesGrid.module.scss";
import {useLegacySvg} from '../hooks/use-legacy-svg';

const ServicesGrid = ({ services, homepage = false }) => {
  const getLegacySvg = useLegacySvg;
  services.map(i => {
    i.imageUrl = getLegacySvg(i.image)[0].node.publicURL
    return i;
  })
  return (
    <section className={homepage ? styles.homepage_grid : styles.grid}>
      {services.map((service) => (
        <div key={service.title} className={styles.container}>
          <div>
            <img
              src={service.imageUrl}
              alt={service.title}
              className={styles.image}
              style={homepage ? { width: "160px" } : {}}
            />

            <h1 className={styles.title}>{service.title}</h1>
            {service.description.map((p, k) => (
              <p key={k} className={styles.description}>
                {p}
              </p>
            ))}
          </div>
          {homepage && (
            <ScrollingButton
              to={`/services/${service.to}`}
              bg="#ed76bc"
              color="white"
              text="Find out more"
              id={styles.button}
            />
          )}
        </div>
      ))}
    </section>
  );
};

export default ServicesGrid;
