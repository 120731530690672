import React, { useEffect, useState } from "react";
import * as styles from "./Carousel.module.scss";
import { animated, useTransition } from "react-spring";

export const IconBackCircle = ({ height, width, ...otherProps }) => (
  <svg
    width={width ? width : "32"}
    height={height ? height : "32"}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M32 56C45.2548 56 56 45.2548 56 32C56 18.7452 45.2548 8 32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56Z"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34 26L28 32L34 38"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconForwardCircle = ({ height, width, ...otherProps }) => (
  <svg
    width={width ? width : "32"}
    height={height ? height : "32"}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56C45.2548 56 56 45.2548 56 32C56 18.7452 45.2548 8 32 8Z"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M30 38L36 32L30 26"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function Carousel(props) {
  const { children } = props;

  const [displayed, setDisplayed] = useState(0);
  const [direction, setDirection] = useState(1);

  const moveForward = () => {
    setDirection(1);

    setDisplayed((x) => (x + 1) % children.length);
  };
  const moveBackward = () => {
    setDirection(-1);

    setDisplayed((x) => {
      if (x - 1 < 0) return children.length - 1;
      return x - 1;
    });
  };

  useEffect(() => {
    const handle = setInterval(() => {
      setDirection(-1);
      moveForward();
    }, 8000);

    return () => clearInterval(handle);
  }, [displayed]);

  const transitions = useTransition(displayed, {
    config: {
      mass: 1,
      tension: 280,
      friction: 60,
      precision: 0.001,
      velocity: 0
    },
    from: { opacity: 0, transform: `translate3d(${direction * 100}%,0,0)` },
    enter: { opacity: 1, transform: `translate3d(0%,0,0)` },
    leave: { opacity: 0, transform: `translate3d(${direction * -100}%,0,0)` },
  });

  return (
    <>
      <h4 className={styles.header}>Projects</h4>
      <div className={styles.layout}>
        <div className={styles.buttonContainer}>
          <IconBackCircle onClick={moveBackward} className={styles.button} />
        </div>

        {transitions((props, item, key) => {
          return (
            <animated.div
              style={{ ...props, gridArea: "1 / 2 / 3 / 3" }}
              key={key}
            >
              {children[item]}
            </animated.div>
          );
        })}

        <div className={styles.buttonContainer}>
          <IconForwardCircle onClick={moveForward} className={styles.button} />
        </div>
      </div>
    </>
  );
}
