// extracted by mini-css-extract-plugin
export var br = "StatsOverview-module--br--L-m8n";
export var code = "StatsOverview-module--code--3hvgT";
export var downArrow = "StatsOverview-module--downArrow--s0hiP";
export var flex_grid = "StatsOverview-module--flex_grid--+Bv6Y";
export var homepage_stat = "StatsOverview-module--homepage_stat--usAk-";
export var icon = "StatsOverview-module--icon--E1Cng";
export var largerThan = "StatsOverview-module--largerThan--7s0mf";
export var link = "StatsOverview-module--link--lTCKM";
export var mobileBr = "StatsOverview-module--mobileBr--bY3bl";
export var orange = "StatsOverview-module--orange--jW3WT";
export var pink = "StatsOverview-module--pink--fB9Ar";
export var smallPad = "StatsOverview-module--smallPad--j9Vq+";
export var stat = "StatsOverview-module--stat--H7Hy3";
export var statItem = "StatsOverview-module--statItem--DadMi";
export var statName = "StatsOverview-module--statName--OBZ+v";
export var statRow = "StatsOverview-module--statRow--m7AWN";
export var teal = "StatsOverview-module--teal--N6WFx";