import React, { useMemo } from 'react'
import toOptimisedImg from "./OptimisedImgUtil";

const SIZES = [2550, 2175, 1875, 1350, 975, 750, 375]

function OptzImage({ src, ...props }, ref) {
    const srcSet = useMemo(() => {

        if (
            !src.endsWith('.jpg') &&
            !src.endsWith('.jpeg') &&
            !src.endsWith('.png') &&
            !src.endsWith('.webp')
        )
            return null

        const srcSetImgs = SIZES.map((size) => `${toOptimisedImg(src, size)} ${size}w`)

        return [toOptimisedImg(src, null), srcSetImgs.join(', ')]
    }, [])

    if (srcSet === null) {
        return <img ref={ref} src={src} {...props} />
    }

    return <img ref={ref} src={srcSet[0]} srcSet={srcSet[1]} {...props} />
}

export default React.forwardRef(OptzImage)
