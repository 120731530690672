const IntroSlides = [
  {
      keyword: 'manufacture',
      bg: '#1d1d1d',
      keywordColor: '#00c9c9',
      color: 'white',
      image: 'landing-2.webp',
      button: {
          bg: '#00c9c9',
          color: 'white',
      },
      backgroundSize: '100vw auto',
  },
  {
      keyword: 'import',
      bg: '#d4d9ff',
      keywordColor: 'white',
      button: {
          bg: 'white',
          color: 'black',
      },
      image: 'landing-5.webp',
      backgroundSize: '100vw auto',
  },
  {
      keyword: 'distribute',
      bg: '#fffdfe',
      keywordColor: '#ed76bc',
      color: 'black',
      button: {
          bg: '#ed76bc',
          color: 'white',
      },
      image: 'landing-4.webp',
      backgroundSize: '100vw auto',
  },
  {
      keyword: 'source',
      bg: '#f4dfd4',
      color: 'black',
      keywordColor: '#ff692c',
      image: 'landing-1.webp',
      button: {
          bg: '#ff692c',
          color: 'white',
      },
      backgroundSize: '100vw auto',
  },
]

export default IntroSlides
