const HomepageServices = [
  {
      title: 'Product Sourcing',
      description: [
          "We'll research the best manufacturers around the globe for you, depending on your own unique business requirements.",
          "Whether it is consistency, reliability, price, or engineering capability that you are after - Sourci has you covered.",
      ],
      image: 'product-sourcing.svg',
      to: 'product-sourcing',
  },
  {
      title: 'Manufacturing',
      description: [
          'We manage everything to do with your manufacturing - including perfecting sample prototypes, handling quality control, expert negotiation and all necessary due diligence.',
          "You will be provided with regular live progress updates and we'll work through product improvements together as a team.",
      ],
      image: 'product-manufacturing.svg',
      to: 'product-manufacturing',
  },
  {
      title: 'Importing',
      description: [
          'Once the perfect supplier has been chosen and we’ve negotiated the best possible pricing & terms on your behalf, Sourci will handle the entire importing process for you.',
          "We'll organise freight, prepare all documentation, and get your products straight to your nominated location!",
      ],
      image: 'freight-importing.svg',
      to: 'freight-importing',
  },
  {
      title: 'Distribution',
      description: [
          'Warehousing, palletising and distribution of your products - all managed under the one roof.',
          'Sourci has a network of warehouses across the nation (and globally) that can facilitate the entire storage, fulfillment and distribution life cycle with full integration to your ecommerce platform.',
      ],
      image: 'distribution.svg',
      to: 'distribution',
  },
]

export default HomepageServices
