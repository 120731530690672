// extracted by mini-css-extract-plugin
export var attribute = "ProjectCarouselItem-module--attribute--bvtx0";
export var blurb = "ProjectCarouselItem-module--blurb--Xyt81";
export var blurbE = "ProjectCarouselItem-module--blurbE--dnvWa";
export var br = "ProjectCarouselItem-module--br--aRO3-";
export var button = "ProjectCarouselItem-module--button--1CPIA";
export var buttonContainer = "ProjectCarouselItem-module--buttonContainer--a4clE";
export var code = "ProjectCarouselItem-module--code--LzZgD";
export var content = "ProjectCarouselItem-module--content--6O8p-";
export var header = "ProjectCarouselItem-module--header--leGD9";
export var image = "ProjectCarouselItem-module--image--Nlkuy";
export var imageContainer = "ProjectCarouselItem-module--imageContainer--avK5m";
export var layout = "ProjectCarouselItem-module--layout--z1HV1";
export var link = "ProjectCarouselItem-module--link--qmyse";
export var lpLayout = "ProjectCarouselItem-module--lpLayout--YqTcj";
export var mobileBr = "ProjectCarouselItem-module--mobileBr--btCbi";
export var orange = "ProjectCarouselItem-module--orange--y8L7f";
export var pink = "ProjectCarouselItem-module--pink--vvexk";
export var teal = "ProjectCarouselItem-module--teal---bcUe";