import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function useGSScrollFromTo({
  start,
  end,
  from,
  to,
  scrollTriggerProps,
}) {
  const ref = useRef();

  useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start,
        scrub: true,
        end,
        ...scrollTriggerProps,
      },
    });

    timeline.fromTo(ref.current, from, to);
  }, []);

  const setRef = (el) => (ref.current = el);

  return { ref: setRef };
}
