import StatsOverview from "./StatsOverview";
import * as styles from "./HomepageStat.module.scss";
import React from "react";

export default function HompageStat({ stats }) {
  return (
    <div className={styles.ctn}>
      {stats.map((stat, index) => (
        <div key={index}
          className={styles.stat}
          id={
            index % 3 === 0
              ? styles.anim_teal
              : index % 2
              ? styles.anim_pink
              : styles.anim_orange
          }
        >
          <StatsOverview 
            name={stat.name}
            stat={stat.value}
            before={stat.before}
            after={stat.after}
            textonly={stat.text_only}
            text={stat.text}
            homepage={stats.length === 3}
          />
        </div>
      ))}
    </div>
  );
}
