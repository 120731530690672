
import useMouse from '@react-hook/mouse-position'
import {Link} from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useSpring } from 'react-spring'
import * as styles from './SelectableImage.module.scss'
import OptzImage from './OptzImage'

export const IconRightArrow = ({ color, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={color}
      viewBox="0 0 256 256"
      {...otherProps}
    >
      <rect width="256" height="256" fill="none"></rect>
      <line
        x1="40"
        y1="128"
        x2="216"
        y2="128"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      ></line>
      <polyline
        points="144 56 216 128 144 200"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      ></polyline>
    </svg>
  );
};

function useSSRMouse(ref) {


    const mouse = useMouse(ref, {
        enterDelay: 100,
        leaveDelay: 0,
    })

    return mouse
}
export default function SelectableImage({
    bg = '#000000',
    color = '#ffffff',
    label = 'No Label',
    to,
    children,
    parallax = false,
    outerClassName = {},
    innerClassName = {},
    mobile = false,
    mobilebutton = false,
    mobileposition = {
        bottom: '0%',
        left: '0%',
    },
    ...props
}) {
    const [defaults, setDefaults] = useState({ x: 0, y: 0 })

    const ref = React.useRef(null)
    const imagePillRef = React.useRef()

    const [pillWidth, setPillWidth] = useState(0)

    useEffect(() => {
        if (!imagePillRef.current) return

        setPillWidth(imagePillRef.current.getBoundingClientRect().width)
    }, [imagePillRef])

    const { isOver } = useSSRMouse(ref, {
        enterDelay: 100,
        leaveDelay: 0,
    })

    const [pos, setPos] = useState({ x: 0, y: 0 })

    const animProps = useSpring({
        from: {
            top: '0px',
            left: '0px',
            opacity: '0',
        },
        top: `${pos.y || defaults.y}px`,
        left: `${pos.x || defaults.x}px`,
        opacity: isOver ? '1' : '0',
    })

    const handleMove = (e) => {
        // don't ask
        setPos({
            x: Math.min(
                e.nativeEvent.clientX - ((parallax && e.target.getBoundingClientRect().x) || 0),
                document.documentElement.clientWidth -
                    (parallax && e.target.getBoundingClientRect().x) -
                    pillWidth -
                    20
            ),
            y: e.nativeEvent.clientY - ((parallax && e.target.getBoundingClientRect().y) || 0),
        })
    }

    return (
        <div className="t" style={{ overflow: 'hidden' }}>
            <Link to={to}>
                <a className={outerClassName}>
                    <OptzImage
                        className={innerClassName}
                        ref={ref}
                        onMouseMove={handleMove}
                        style={{ cursor: 'pointer' }}
                        {...props}
                    />
                    {mobile && (
                        <div
                            className={styles.label}
                            mobileposition={mobileposition}
                            bg={bg}
                            color={color}
                            style={{
                                background: bg,
                                display: 'flex',
                                color: color,
                                bottom: mobileposition.bottom,
                                left: mobileposition.left ? mobileposition.left : 'none',
                                right: mobileposition.right ? mobileposition.right : 'none',
                                boxShadow: `0px 0px 10px 0px ${bg}`,
                            }}
                        >
                            <div>{label}</div>
                            <IconRightArrow color={color} style={{ marginLeft: '0.5rem' }} />
                        </div>
                    )}
                    {mobilebutton && (
                        <div
                            className={styles.button}
                            bg={bg}
                            color={color}
                            style={{
                                display: 'flex',
                                background: bg,
                                color: color,
                                boxShadow: `0px 0px 10px 0px ${bg}`,
                            }}
                        >
                            <div>{label}</div>
                            <IconRightArrow color={color} style={{ marginLeft: '0.5rem' }} />
                        </div>
                    )}
                </a>
            </Link>
        </div>
    )
}
