// extracted by mini-css-extract-plugin
export var animated = "ScrollingButton-module--animated--thH4o";
export var baseButton = "ScrollingButton-module--baseButton--GmjTm";
export var bigButton = "ScrollingButton-module--bigButton--Qpwwt";
export var br = "ScrollingButton-module--br--AZ3ij";
export var button = "ScrollingButton-module--button--iWyOk";
export var code = "ScrollingButton-module--code--9rKOJ";
export var disabled = "ScrollingButton-module--disabled--hnmZf";
export var link = "ScrollingButton-module--link--nADWW";
export var marquee = "ScrollingButton-module--marquee--8333F";
export var mobileBr = "ScrollingButton-module--mobileBr--Qoogf";
export var orange = "ScrollingButton-module--orange--rOO2u";
export var pink = "ScrollingButton-module--pink--p5EQI";
export var teal = "ScrollingButton-module--teal--MKPyx";