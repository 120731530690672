// extracted by mini-css-extract-plugin
export var br = "Page-module--br--oJCQr";
export var code = "Page-module--code--dTqeH";
export var layout = "Page-module--layout--W1QOv";
export var layoutLargePad = "Page-module--layoutLargePad--7cR1-";
export var layoutNoPad = "Page-module--layoutNoPad--3zsA1";
export var link = "Page-module--link--jm+RT";
export var mobileBr = "Page-module--mobileBr--ZoMem";
export var orange = "Page-module--orange--HzTOF";
export var pink = "Page-module--pink--IVM7+";
export var teal = "Page-module--teal--fVf+0";