import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import * as styles from "./StatsOverview.module.scss";

export const StatsOverviewBar = ({ smallPad = false, children }) => {
  return (
    <div className={`${styles.flex_grid} ${smallPad && styles.smallPad}`}>
      {children}
    </div>
  );
};

export const StatsOverview = ({
  icon,
  name = "Defect rate",
  stat = 10,
  before,
  after,
  textonly = false,
  nostat = false,
  countUpProps = {},
  style = {},
  homepage,
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div className={styles.statItem} ref={ref}>
      {!nostat && (
        <div className={styles.statRow}>
          <Icon icon={icon} />
          {textonly ? (
            <span
              className={homepage ? styles.homepage_stat : styles.stat}
              style={style && style}
            >
              {stat}
            </span>
          ) : (
            <span
              className={homepage ? styles.homepage_stat : styles.stat}
              style={style && style}
            >
              {before && before}
              <CountUp
                end={stat}
                start={inView}
                separator=","
                {...countUpProps}
              />
              {after && after}
            </span>
          )}
        </div>
      )}

      <div className={styles.statName}>{name}</div>
    </div>
  );
};

export default StatsOverview;

const Icon = ({ icon }) => {
  switch (icon) {
    case "upArrow":
      return <Arrow className={styles.icon} />;

    case "downArrow":
      return <Arrow className={styles.downArrow} />;

    case "smallerThan":
      return <Angle className={styles.icon} />;

    case "largerThan":
      return <Angle className={styles.largerThan} />;

    default:
      return null;
  }
};

const Arrow = (props) => {
  return (
    <svg
      width="11"
      height="27"
      viewBox="0 0 22 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 52L11 2"
        stroke="black"
        strokeWidth="3.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 11L11 2L2 11"
        stroke="black"
        strokeWidth="3.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Angle = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 26L10 16L20 6"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
