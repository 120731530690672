import SelectableImage from './SelectableImage';
import React from 'react'
import * as styles from './CircularImage.module.scss'

import useGSScrollFromTo from "./useGSScrollFromTo";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const GSScrollFromTo = ({
  as = "div",
  className,
  children,
  start,
  end,
  from,
  to,
  style = {},
  ...scrollTriggerProps
}) => {
  const { ref } = useGSScrollFromTo({
    start,
    end,
    from,
    to,
    scrollTriggerProps,
  });

  return React.createElement(as, { ref, className, style }, children);
};

const CircularImage = ({
    url,
    label = 'No label',
    bg = '#000000',
    color = '#ffffff',
    to = '#',
}) => {
    const hProps = {
        as: 'span',
        start: 'top 80%',
        end: 'bottom 65%',
        from: {
            color: '#000000',
        },
    }

    const fProps = {
        as: 'span',
        start: 'top 90%',
        end: 'bottom 78%',
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    }

    return (
        <div className={styles.layout}>
            <GSScrollFromTo
                start="top bottom"
                end="150% top"
                from={{
                    yPercent: -30,
                }}
                to={{
                    yPercent: 20,
                }}
            >
                <SelectableImage
                    bg={bg}
                    color={color}
                    label={label}
                    to={to}
                    src={url}
                    alt={label}
                    className={styles.clipCircle}
                    parallax
                    mobile
                    mobileposition={{ bottom: '14%', left: '25%' }}
                />
            </GSScrollFromTo>

            <section className={styles.textBox}>
                <h6>
                    Jump to the front
                    <br />
                    of the queue.
                </h6>
                <h6>Procure. Secure.</h6>
                <h6>
                    Go sparkly.
                    <br />
                    <GSScrollFromTo {...fProps}>
                        <GSScrollFromTo {...hProps} to={{ color: '#FF6CBF' }}>
                            Ethical,
                        </GSScrollFromTo>
                    </GSScrollFromTo>
                    <br />
                    <GSScrollFromTo {...fProps}>
                        <GSScrollFromTo {...hProps} to={{ color: '#00C9C9' }}>
                            organic,
                        </GSScrollFromTo>
                    </GSScrollFromTo>
                    <br />
                    <GSScrollFromTo {...fProps}>
                        <GSScrollFromTo {...hProps} to={{ color: '#FF692C' }}>
                            rainbow sparkly.
                        </GSScrollFromTo>
                    </GSScrollFromTo>
                </h6>
            </section>
        </div>
    )
}

export default CircularImage
