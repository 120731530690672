import ScrollingButton from "./ScrollingButton";
import toOptimisedImg from "./OptimisedImgUtil";
import React, { useRef, useState } from "react";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import * as styles from "./Slides.module.scss";
import {useLegacyImage} from '../hooks/use-legacy-image';

SwiperCore.use([EffectFade, Autoplay, Pagination]);
const DEFAULT_LARGE_SIZE = 2175;

const SmallArrow = ({ color, ...props }) => {
  return (
    <svg
      width="33"
      height="47"
      viewBox="0 0 33 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 1L16.5 41"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 37L16.5 41L13 37"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Slides = ({ slides, handleScroll }) => {
  const getLegacyImage = useLegacyImage;
  slides.map(i => {
    i.imageUrl = getLegacyImage(i.image)[0].node.publicURL
    return i;
  })
  const swipeRef = useRef();
  const [index, setIndex] = useState(0);

  const handleDotClick = (k) => {
    swipeRef.current.slideTo(k + 1);
  };

  return (
    <Swiper
      className={styles.backgroundDiv}
      onSlideChange={(e) => setIndex(e.realIndex)}
      loop={true}
      onSwiper={(swiper) => (swipeRef.current = swiper)}
      effect="fade"
      initialSlide={slides.length - 1}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      {slides.map((props, key) => (
        <SwiperSlide key={key}>
          <IntroSlide handleScroll={handleScroll} {...props} />
        </SwiperSlide>
      ))}
      <div className={styles.dotContainer}>
        {[...Array(slides.length).keys()].map((k) => (
          <div
            className={styles.dot}
            key={k}
            onClick={() =>
              handleDotClick((k + slides.length - 1) % slides.length)
            }
            style={{
              background:
                index === (k + slides.length - 1) % slides.length &&
                slides[index].color
                  ? slides[index].color
                  : index === (k + slides.length - 1) % slides.length
                  ? "black"
                  : "transparent",
              borderColor: slides[index].color ? slides[index].color : "black",
            }}
          />
        ))}
      </div>
    </Swiper>
  );
};

export default Slides;

const IntroSlide = ({
  onClick,
  keyword,
  bg,
  color,
  keywordColor,
  imageUrl,
  button: buttonStyle = {},
  backgroundSize = "50vw auto",
  noKeyword = false,
  handleScroll,
}) => {
  return (
    <div
      onClick={onClick}
      className={styles.slide}
      style={{
        color,
        backgroundImage: `url("${toOptimisedImg(imageUrl, DEFAULT_LARGE_SIZE)}")`,
        backgroundColor: bg,
        backgroundSize,
      }}
    >
      <div className={styles.text}>
        <div className={styles.headerContainer}>
          Hello, what <br />
          can we&nbsp;
          <h1 className={styles.keyword} style={{ color: keywordColor }}>
            {keyword}
          </h1>
          &nbsp;
          <br />
          for you today?
        </div>
        <SmallArrow
          color={color ? color : "black"}
          className={styles.arrow}
          onClick={handleScroll}
        />
        <ScrollingButton
          bg={buttonStyle.bg}
          color={buttonStyle.color}
          text="Get started"
          to="/contact"
        />
      </div>
    </div>
  );
};
