const SIZES = [2550, 2175, 1875, 1350, 975, 750, 375]
const DEFAULT_SIZE = 975
const DEFAULT_LARGE_SIZE = 2175

export default function toOptimisedImg(url, w = null) {
  if (process.env.NEXT_PUBLIC_ENV !== "production") {
    return url;
  }

  if (
    !url.endsWith(".jpg") &&
    !url.endsWith(".jpeg") &&
    !url.endsWith(".png") &&
    !url.endsWith(".webp")
  )
    return url;

  let size = DEFAULT_SIZE;

  if (w !== null && SIZES.includes(w)) {
    size = w;
  }

  const filePath = url
    .replace("/assets/images", "/assets/optzimg")
    .replace(".png", "")
    .replace(".jpg", "")
    .replace(".jpeg", "")
    .replace(".webp", "");

  return `${filePath}-${size}w.jpg`;
}
