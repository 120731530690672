// extracted by mini-css-extract-plugin
export var bigText = "homepageBody-module--bigText--qZ2fA";
export var bowlDiv = "homepageBody-module--bowlDiv--c5MEa";
export var bowlImg = "homepageBody-module--bowlImg--c22mk";
export var bowlImgPlx = "homepageBody-module--bowlImgPlx--NScrL";
export var bowlText = "homepageBody-module--bowlText--0JuVU";
export var br = "homepageBody-module--br--PMHp7";
export var button = "homepageBody-module--button--uScuW";
export var cameraDiv = "homepageBody-module--cameraDiv--yqVDv";
export var cameraImg = "homepageBody-module--cameraImg--YjWfk";
export var cameraImgPlx = "homepageBody-module--cameraImgPlx--NkOm4";
export var code = "homepageBody-module--code--wY4HJ";
export var column_layout = "homepageBody-module--column_layout--zgfEC";
export var h2 = "homepageBody-module--h2--Ns1wT";
export var innerDivNike = "homepageBody-module--innerDivNike--vG95N";
export var layout = "homepageBody-module--layout--Fcfo8";
export var link = "homepageBody-module--link--tk534";
export var list = "homepageBody-module--list--VfQJ6";
export var mobileBr = "homepageBody-module--mobileBr--rkEbF";
export var orange = "homepageBody-module--orange--zcXsX";
export var outerDivNike = "homepageBody-module--outerDivNike--bFGLK";
export var pink = "homepageBody-module--pink--IFQYV";
export var platformDiv = "homepageBody-module--platformDiv--nt7E5";
export var platformImg = "homepageBody-module--platformImg--dK44c";
export var relative_div = "homepageBody-module--relative_div--ladSM";
export var scrollWidthText = "homepageBody-module--scrollWidthText--34zf4";
export var tagline = "homepageBody-module--tagline--6Qpwi";
export var teal = "homepageBody-module--teal--ZiCXO";
export var white_tagline = "homepageBody-module--white_tagline--8hLTv";