// extracted by mini-css-extract-plugin
export var br = "ServicesGrid-module--br--r0+Yv";
export var button = "ServicesGrid-module--button--zkO+k";
export var code = "ServicesGrid-module--code--BcsgL";
export var container = "ServicesGrid-module--container--EiKZB";
export var description = "ServicesGrid-module--description--Q1m5u";
export var grid = "ServicesGrid-module--grid--I8qbH";
export var homepage_grid = "ServicesGrid-module--homepage_grid--HWaL2";
export var image = "ServicesGrid-module--image--LTrsh";
export var link = "ServicesGrid-module--link--gyj26";
export var mobileBr = "ServicesGrid-module--mobileBr--uezeK";
export var orange = "ServicesGrid-module--orange--FiGHx";
export var pink = "ServicesGrid-module--pink--8MGEx";
export var teal = "ServicesGrid-module--teal--JliAQ";
export var title = "ServicesGrid-module--title--lWJT5";