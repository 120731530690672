import { Link } from "gatsby";
import React from "react";
import * as styles from "./ScrollingButton.module.scss";

const ScrollingButton = ({
  text = "Get started",
  bg,
  color,
  id,
  to,
  big,
  disabled,
  animated = false,
  style = {},
  ...props
}) => {
  if (to) {
    return (
      <div>
        <Link to={to}>
          <a>
            <button
              id={id}
              className={`${styles.baseButton}  ${
                animated ? styles.animated : styles.button
              } ${big && styles.bigButton} `}
              text={text}
              bg={bg}
              style={{
                background: bg,
                color: color,
                "--button-bg": bg,
                "--button-display": props.mobile ? "none" : "block",
                ...style,
              }}
            >
              <span data-content={text}>{text}</span>
            </button>
          </a>
        </Link>
      </div>
    );
  }
  return (
    <button
      id={id}
      className={`${styles.baseButton} ${
        disabled ? styles.disabled : animated ? styles.animated : styles.button
      }`}
      text={text}
      bg={bg}
      style={{
        background: bg,
        color: color,
        "--button-bg": bg,
        "--button-display": props.mobile ? "none" : "block",
        ...style,
      }}
      {...props}
    >
      <span data-content={text}>{text}</span>
    </button>
  );
};

export default ScrollingButton;
