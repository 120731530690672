import React from "react";
import * as styles from "./BrandTabReverse.module.scss";
import { graphql, useStaticQuery } from "gatsby";

import useGSScrollFromTo from "./useGSScrollFromTo";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const GSScrollFromTo = ({
  as = "div",
  className,
  children,
  start,
  end,
  from,
  to,
  style = {},
  ...scrollTriggerProps
}) => {
  const { ref } = useGSScrollFromTo({
    start,
    end,
    from,
    to,
    scrollTriggerProps,
  });

  return React.createElement(as, { ref, className, style }, children);
};

export const useLegacyImage = (file_names) => {
  if (typeof file_names !== "object") {
    file_names = [file_names];
  }
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "legacy" }
        }
      ) {
        edges {
          node {
            ext
            name
            publicURL
          }
        }
      }
    }
  `);
  return data.allFile.edges.filter((e) =>
    file_names.includes(`${e.node.name}${e.node.ext}`)
  );
};

export const BrandLogo = ({ logo, i, className }) => {
  return (
    <GSScrollFromTo
      as="div"
      start="top 80%"
      end="bottom 80%"
      className={className}
      from={{
        opacity: 0,
        xPercent: -10,
      }}
      to={{
        opacity: 1,
        xPercent: 0,
      }}
    >
      <img
        src={useLegacyImage(logo)[0].node.publicURL}
        alt="brand logo"
        width="100%"
      />
    </GSScrollFromTo>
  );
};

const BrandTabReverse = ({ logos }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.tab}>
        <h6 className={styles.brand_tab_h6}>Some Of Our Brands:</h6>
        <div className={styles.grid}>
          {logos.map((logo, i) => (
            <BrandLogo
              key={i}
              i={i}
              logo={logo}
              className={(i === 4 || i === 5) ? styles.lastLogoRow : undefined}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandTabReverse;
