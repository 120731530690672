import React, { useMemo } from "react";
import * as styles from "./Page.module.scss";

export default function Page(props) {
  const {
    children,
    background,
    noPad = false,
    white = false,
    largePad = false,
    boxShadow = "",
    backgroundPosition = "",
    backgroundSize = "",
    ...otherProps
  } = props;

  const className = useMemo(() => {
    if (noPad) return styles.layoutNoPad;
    if (largePad) return styles.layoutLargePad;
    return styles.layout;
  }, [noPad, largePad]);

  return (
    <div
      className={className}
      style={
        white
          ? { backgroundColor: "#fff" }
          : background
          ? { background, boxShadow, backgroundPosition, backgroundSize }
          : {}
      }
      {...otherProps}
    >
      {children}
    </div>
  );
}
