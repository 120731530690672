// extracted by mini-css-extract-plugin
export var br = "CircularImage-module--br--htPEk";
export var clipCircle = "CircularImage-module--clipCircle--2vN8E";
export var code = "CircularImage-module--code--jFTH5";
export var layout = "CircularImage-module--layout--0Z48H";
export var link = "CircularImage-module--link--eXMLQ";
export var mobileBr = "CircularImage-module--mobileBr--l+0DK";
export var orange = "CircularImage-module--orange--UnJ8e";
export var pink = "CircularImage-module--pink--oWZHe";
export var teal = "CircularImage-module--teal--sCFrz";
export var textBox = "CircularImage-module--textBox--a7y2a";