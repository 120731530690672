import React from "react";
import * as styles from "./ScrollableQuote.module.scss";

import useGSScrollFromTo from "./useGSScrollFromTo";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const GSScrollFromTo = ({
  as = "div",
  className,
  children,
  start,
  end,
  from,
  to,
  style = {},
  ...scrollTriggerProps
}) => {
  const { ref } = useGSScrollFromTo({
    start,
    end,
    from,
    to,
    scrollTriggerProps,
  });

  return React.createElement(as, { ref, className, style }, children);
};

const ScrollableQuote = ({ quote }) => {
  return (
    <div className={styles.innerDiv}>
      <GSScrollFromTo
        as="h1"
        start="top bottom"
        end="bottom -200%"
        from={{ xPercent: 100 }}
        to={{ xPercent: -200 }}
        className={styles.quote}
      >
        {quote}
      </GSScrollFromTo>
    </div>
  );
};

export default ScrollableQuote;
