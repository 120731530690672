import React, { useState, useMemo } from 'react'

export default function useHomeBrandTabs() {
    const [inView, setInview] = useState(false)
    const [userHasHidden, setUserHasHidden] = useState(false)
    const [forceHide, setForceHide] = useState(false)

    const showTab = useMemo(() => {
        return (inView && !forceHide) || !userHasHidden
    }, [inView, userHasHidden, forceHide])

    const onTabClick = () => {
        setForceHide(true)
        setUserHasHidden(true)
    }

    const onInViewChange = (inView) => {
        setInview(inView)

        if (inView) {
            setForceHide(false)
        }
    }

    return {
        onTabClick,
        onInViewChange,
        showTab,
        inView,
    }
}
