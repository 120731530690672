// extracted by mini-css-extract-plugin
export var big_text = "Video-module--big_text--2LQsM";
export var br = "Video-module--br--B1al8";
export var code = "Video-module--code--5-EBy";
export var layoutGrid = "Video-module--layoutGrid--IV+pM";
export var link = "Video-module--link--3lmf+";
export var mobileBr = "Video-module--mobileBr--Bx41A";
export var orange = "Video-module--orange--e7MJl";
export var pink = "Video-module--pink--xCuvy";
export var react_player = "Video-module--react_player--9+O7s";
export var teal = "Video-module--teal--VvIXO";
export var video_inner_ctn = "Video-module--video_inner_ctn--FpaLc";