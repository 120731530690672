// extracted by mini-css-extract-plugin
export var br = "BrandTab-module--br--XOvl7";
export var code = "BrandTab-module--code--+4DqE";
export var grid = "BrandTab-module--grid--aBCR8";
export var lastLogoRow = "BrandTab-module--lastLogoRow--emJWH";
export var layout = "BrandTab-module--layout--saps3";
export var link = "BrandTab-module--link--Bu9M0";
export var mobileBr = "BrandTab-module--mobileBr--GXCkV";
export var orange = "BrandTab-module--orange--Zx2MH";
export var pink = "BrandTab-module--pink--mGleA";
export var tab = "BrandTab-module--tab--wL7A7";
export var teal = "BrandTab-module--teal--jEvI1";