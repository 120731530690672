// extracted by mini-css-extract-plugin
export var anim_orange = "HomepageStat-module--anim_orange--V7vxP";
export var anim_pink = "HomepageStat-module--anim_pink--X1vl1";
export var anim_teal = "HomepageStat-module--anim_teal--ZKi45";
export var br = "HomepageStat-module--br--6hflI";
export var code = "HomepageStat-module--code--Z7KSZ";
export var ctn = "HomepageStat-module--ctn--k01PN";
export var link = "HomepageStat-module--link--udeQ7";
export var mobileBr = "HomepageStat-module--mobileBr--VMhHL";
export var orange = "HomepageStat-module--orange--lM6BE";
export var pink = "HomepageStat-module--pink--o8IV8";
export var stat = "HomepageStat-module--stat--YTgxD";
export var teal = "HomepageStat-module--teal--KXJ5m";