import { graphql, useStaticQuery } from "gatsby";

export const useLegacySvg = (file_names) => {
  if(typeof file_names !== 'object') {
    file_names = [file_names];
  }
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "legacy"}, ext: {eq: ".svg"}}) {
        edges {
          node {
            ext
            name
            publicURL
          }
        }
      }
    }
  `)
  return data.allFile.edges.filter(e => file_names.includes(`${e.node.name}${e.node.ext}`));
}