// extracted by mini-css-extract-plugin
export var bagDiv = "Landing-module--bagDiv--qUhtD";
export var br = "Landing-module--br--rL-dq";
export var code = "Landing-module--code--eHB9p";
export var div2 = "Landing-module--div2--uONEd";
export var div2_ST001 = "Landing-module--div2_ST001--jGcGH";
export var image = "Landing-module--image--r9vj1";
export var last_h1 = "Landing-module--last_h1--yC9Kp";
export var layout = "Landing-module--layout--26HNI";
export var link = "Landing-module--link--6NywT";
export var mobileBr = "Landing-module--mobileBr--1jbj6";
export var orange = "Landing-module--orange--LTXfl";
export var pink = "Landing-module--pink--cILl-";
export var processText = "Landing-module--processText--0ZG69";
export var tagline = "Landing-module--tagline--ewSeJ";
export var teal = "Landing-module--teal--eyNGQ";
export var toothbrushDiv = "Landing-module--toothbrushDiv--IWqyL";